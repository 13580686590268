/* eslint-disable array-callback-return */
/* eslint-disable no-console */

import axios from 'axios';
import FormData from 'form-data';

export const prefixCountryFormSubmit = (values) => {
  const formData = new FormData();
  Object.entries(values).map(([key, value]) => {
    if (key !== 'note1') {
      formData.append(key, value);
    } else if (sessionStorage.getItem('note1')) {
      const note1 = sessionStorage.getItem('note1');
      formData.append('note1', note1);
    }
  });
  formData.append('query_string', window.location.search);
  const pathname = window.location.pathname.replace(/\//g, '');

  return axios
    .post(`/${pathname}/api/leads`, formData)
    .then(({ data, status }) => {
      return { data, status };
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response.data, status: error.response.status };
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error);
      return error.response.status;
    });
};

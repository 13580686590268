import React, { useEffect } from 'react';

var useHandleClickOutside = function useHandleClickOutside(ref, callback) {
  useEffect(function () {
    var handleClickOutside = function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return function () {
      return document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);
};

export default useHandleClickOutside;